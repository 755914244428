<template>
    <div style="margin:0 auto">
        <h2>相关标准</h2>
        <Form ref="standardQueryForm" :model="formInline" label-position="right" :label-width="100" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <FormItem  label="标准名称:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="formInline.filename">
                            </i-input>
                        </FormItem>
                    </td>
                    <td align="left">
                        <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                            <Button type="primary" @click="handleQuery()" >查询</Button>    
                            <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">标准文件列表</label>  
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
        </div> 
        <Table :columns="tblcolumns" :data="tbldata" stripe border style="margin-top: 10px;">
            <template slot-scope="{ row, index }" slot="filename" >
                <div >
                    <p v-if="modifyindex !=index">{{row.filename}}</p>
                    <div v-else style="display: inline-flex;justify-content: center;justify-items: center;">
                        <i-input type="text" v-model="modifyfilename">
                        </i-input>
                        <Button type="primary" ghost size="small" @click="domodify(index)" style="margin-left: 5px;">保存</Button>
                        <Button type="error" ghost size="small" @click="modifyindex = null" style="margin-left: 5px;">取消</Button>
                    </div>
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <div  class="opbar">
                    <Button type="success" size="small" ghost @click="view(index)">查看</Button>
                    <Button  v-if="usercategory != 'third' && (row.companyid == companyid )" type="info" size="small" @click="modify(index)">修改</Button>
                    <Button  v-if="usercategory != 'third' && (row.companyid == companyid )" type="error" size="small" @click="remove(index)" >删除</Button>           
                </div>
            </template>
        </Table>
        <Card style="width: 100%;margin-top: 25px;" v-if="usercategory != 'third'">
            <p slot="title">
                <Icon type="ios-film-outline"></Icon>
                添加标准文档
            </p>
            <Form ref="standardAddForm" :model="addFormInline" label-position="right" :label-width="100" style="margin:10px;border:1px solid lightgray;background:rgb(247, 246, 235);">
                <table width="100%">
                    <tr>
                        <td align="right" width="23%">
                            <FormItem  style="margin-bottom:5px;margin-top:10px">
                                <Upload
                                    type="drag"
                                    action="/api/files/ycstandard/files/upload"
                                    :data="{
                                        companyid: this.companyid
                                        }"
                                    :max-size= "51200"
                                    :format="['pdf']"
                                    :on-success="handleSuccess"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    >
                                    <div style="padding: 20px 0">
                                        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                                        <p>点击或拖拽文件进行上传</p>
                                    </div>
                                </Upload>
                            </FormItem>
                        </td>
                        <td align="left" width="23%">
                            <FormItem  label="标准名称:" style="margin-bottom:5px;margin-top:10px">
                                <i-input type="text" v-model="addFormInline.filename">
                                </i-input>
                            </FormItem>
                        </td>
                        <td align="left">
                            <div style="padding-left: 10px;">
                                <Button type="primary" @click="handleAdd()" >保存</Button>    
                            </div>
                        </td>
                    </tr>
                </table>
            </Form>
        </Card>
    </div>
</template>

<script>
export default {
    name:'StandardManage',
    data(){
        return {
            logoninfo: this._self.$root.$store.state.LogonInfo,
            usercategory: this._self.$root.$store.state.LogonInfo.userCategory,
            companyid: this._self.$root.$store.state.LogonInfo.companyid,
            formInline:{
                filename:null
            },
            addFormInline:{
                filename:null,
                filepath:null,
                modifyuser: this._self.$root.$store.state.LogonInfo.userid,
                companyid: this._self.$root.$store.state.LogonInfo.companyid,
            },
            tblpageinfo:{
                total:0,
                currentpage:1,
                pagesize:20
            },
            tblcolumns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '标准名称',
                    slot: 'filename',
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center',
                    fixed: 'right'
                }
                
            ],
            tbldata: [],
            modifyindex:null,
            modifyfilename:null
        }
    },
    mounted(){
        if(this.usercategory == 'superadmin'){
            this.companyid = 1
            this.formInline.companyid = 1
        }
        this.handleQuery();
    },
    methods:{
        view(index){
            /*let pdfurl = encodeURIComponent(this.tbldata[index].filepath)
            let routeUrl = this.$router.resolve({
                path: "/workdesk/PdfView",
                query:{pdfurl:pdfurl}
            });*/

            let openurl = window.location.protocol+"//"+window.location.host+this.tbldata[index].filepath
            window.open(openurl, '_blank');
        },
        modify(index){
            this.modifyindex = index;
            this.modifyfilename = this.tbldata[index].filename
        },
        domodify(index){
            let postdata =  this.tbldata[index]
            postdata.filename = this.modifyfilename
            this.$axios({
                method:'post',
                url:'/api/checkdept/standard/update',
                data: postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.$Message.success({content:res.data.msg, duration:3})
                    this.modifyindex = null
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        remove(index){
            this.$axios({
                method:'post',
                url:'/api/checkdept/standard/delete',
                data: this.tbldata[index],
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        handleQuery(){
            let postdata={
                companyid: this.companyid,
                pageNum: this.tblpageinfo.currentpage,
                pageSize: this.tblpageinfo.pagesize
            }

            if(this.formInline.filename!=null && this.formInline.filename !=''){
                postdata.filename = this.formInline.filename
            }
            this.$axios({
                method:'post',
                url:'/api/checkdept/standard/query',
                data: postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    if(res.data.data.totalnumber == 0){
                        this.tbldata=[]
                    }
                    else{
                        this.tblpageinfo.total = res.data.data.totalnumber
                        this.tbldata =res.data.data.data
                    }
                    
                    console.log(this.tbldata)
                    this.addFormInline.filename = null
                    this.addFormInline.filepath = null
                    this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        handleReset(){
            this.formInline.filename = null
            this.handleQuery()
        },
        handleAdd(){ 
            if(this.addFormInline.filename == null || this.addFormInline.filename == ''){
                this.$Message.error({content:'标准名称不能为空！',duration:3})
                return
            }
            this.$axios({
                method:'post',
                url:"/api/checkdept/standard/addnew",
                data: this.addFormInline,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.handleQuery()
                    this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        onpagechange(num){
            this.tblpageinfo.currentpage = num
            this.handleQuery()
        },
        handleSuccess(response, file, fileList){
            this.addFormInline.filepath = response.data.filepath
            this.addFormInline.filename = response.data.originfilename
            this.$Message.success({content:"文件上传成功", duration:3})
            console.log(this.addFormInline)
        },
        handleFormatError(response, file, fileList){
            this.$Message.error({content:'文件格式错误，请上传pdf文件!', duration:3})
        },
        handleMaxSize(file, fileList){
            this.$Message.error({content:"文件过大，单个文件不能超过50M!", duration:3})
        }
    }
}
</script>
<style scoped>
    .ivu-card-head p, .ivu-card-head-inner{
        text-align: left;
    }
</style>
 